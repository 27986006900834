import Title from 'components/landing/Title';
import About from './About';

export default function AboutSection() {
    return (
        <section className="pt-20 pb-50 bg-gray-100">
            <div style={{marginTop:'2%'}} className="container max-w-7xl mx-auto px-4">
                <Title heading="About Us" >
                Rise Up Prison Ministry is birthed out of a 
                burden that God has placed on our hearts 
                to share the truth of the gospel of Jesus Christ with the 
                incarcerated.  While Jesus came first for the Jew, 
                he consistently ministered to the broken, lost, and forgotten. 
                Our mission is to share the love of Christ with those behind bars, 
                while teaching the Word of God and allowing the Holy Spirit to do 
                the work of salvation.
                </Title>
                <div style={{marginTop:'2%'}} className="flex flex-wrap pb-20">
                    <About/>
    
                </div>
            </div>
        </section>
    );
}
