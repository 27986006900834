import H2 from '@material-tailwind/react/Heading2';
import H1 from '@material-tailwind/react/Heading1'
import logo from '../../assets/img/logo.png';

export default function Header() {
    return (
        <div style={{
            background: '#FFFFF',
        }} className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen">
            <div className="bg-landing-background bg-cover bg-center absolute top-0 w-full h-full" />
            
            <div className="container max-w-8xl relative mx-auto">
                <div className="items-center flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4 ml-5 mr-auto text-center">
                        <H2 style={{ml:5}} color="Black">He Asked Me,</H2>
                        <div className="text-black-200">
                            <H1 color="black" style={{fontSize: 30}}>
                            "Son of man, can these bones live?"
                                I said, "Sovereign Lord, You alone know."
                                Ezekiel 37:3
                            </H1>
                        </div>
                    </div>
                    <div className="col-lg" style={{justifyContent: "center"}}>
                        <img
                        src={logo}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
