import { useState } from 'react';
import Navbar from '@material-tailwind/react/Navbar';
import NavbarContainer from '@material-tailwind/react/NavbarContainer';
import NavbarWrapper from '@material-tailwind/react/NavbarWrapper';
import NavbarBrand from '@material-tailwind/react/NavbarBrand';
import NavbarToggler from '@material-tailwind/react/NavbarToggler';
import NavbarCollapse from '@material-tailwind/react/NavbarCollapse';
import Nav from '@material-tailwind/react/Nav';
import NavLink from '@material-tailwind/react/NavLink';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import NotesIcon from '@mui/icons-material/Notes';
import { Home } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
//TODO See if we can pass logo into DefaultNavbar
export default function DefaultNavbar(logo = <></>) {
    const [openNavbar, setOpenNavbar] = useState(false);

    return (
        <Navbar className="navbar" color="gray"  navbar>
            <NavbarContainer >
                <NavbarWrapper>
                        <NavbarBrand>Rise Up</NavbarBrand>
                    <NavbarToggler
                        onClick={() => setOpenNavbar(!openNavbar)}
                        color="white"
                    />
                </NavbarWrapper>

                <NavbarCollapse open={openNavbar}>
                    <Nav>
                        
                        <div className="flex flex-col z-50 lg:flex-row lg:items-center">
                        <NavLink
                                href="https://donorbox.org/rise-up-prison-ministry"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <VolunteerActivismIcon name="description" size="2xl" />
                                &nbsp;Donate
                            </NavLink>
                        <NavLink
                                href="https://riseupprisonministry.com/"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <Home name="description" size="2xl" />
                                &nbsp;Home
                            </NavLink>
                            <NavLink
                                href="https://riseupprisonministry.com/aboutUs"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <InfoIcon name="description" size="2xl" />
                                &nbsp;About Us
                            </NavLink>
                            <NavLink
                                href="https://riseupprisonministry.com/testimonials"
                                target="_blank"
                                rel="noreferrer"
                                ripple="light"
                            >
                                <NotesIcon name="description" size="2xl" />
                                &nbsp;Testimonials
                            </NavLink>
                        </div>
                    </Nav>
                </NavbarCollapse>
            </NavbarContainer>
        </Navbar>
    );
}


