import H3 from "@material-tailwind/react/Heading3";
import Paragraph from "@material-tailwind/react/Paragraph";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const TransitionAlerts = () => {
  const [open, setOpen] = React.useState(true);

  return (
    <Box sx={{ width: "100%" }}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Close me!
        </Alert>
      </Collapse>
    </Box>
  );
};

export default function Form() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [notification, setNotification] = React.useState("")
  const handleChange = (event, setStateName) => {
    setStateName(event.target.value);
    console.log(event.target.value);
  };

  return (
    <div className="flex flex-wrap justify-center mt-24">
      <div className="w-full lg:w-8/12 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
          <div className="flex-auto p-5 lg:p-10">
            <div className="w-full text-center">
              <H3 color="gray">Want to Contact Us?</H3>
              <Paragraph color="blueGray">
                Complete this form to contact the Rise Up Prison Ministry
              </Paragraph>
            </div>
            <form>
              <div
                className="flex gap-8 mt-16 mb-12"
                style={{ justifyContent: "space-around" }}
              >
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  onChange={(event) => {
                    handleChange(event, setName);
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  onChange={(event) => {
                    handleChange(event, setEmail);
                  }}
                />
              </div>
              <TextField
                id="full-width"
                label="Message"
                multiline
                rows={10}
                style={{ width: "100%" }}
                onChange={(event) => {
                  handleChange(event, setMessage);
                }}
              />

              <div className="flex justify-center mt-10">
                <IconButton
                  aria-label="send"
                  color="secondary"
                  onClick={async (event) => {
                    console.log(event);
                    const resp = await axios.post(
                      "https://mbjrt3dh96.execute-api.us-east-1.amazonaws.com/mailer",
                      {
                        name: name,
                        email: email,
                        message: message,
                      },
                      {
                        headers: {
                          "content-type": "application/json",
                        },
                      }
                    );
                    if (resp.status === 200){
                      setNotification("success")
                    }
                  }}
                >
                  {notification==="success" ? (
                  <Alert variant="success"/>
                  ) : (<></>)}
                  <SendIcon />
                </IconButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
