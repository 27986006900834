import Title from 'components/landing/Title';
import Testimonials from './Testimonials';

export default function TestimonialSection() {
    return (
        <section className="pt-20 pb-50 bg-gray-100">
            <div id='test' className="container max-w-7xl mx-auto px-4">
                <Title heading="Testimonials" >
                    Throughout various prison visits, we have recieved many
                    testimonials from inmates. Some content has been 
                    redacted for privacy purposes.
                </Title>
                <div id='test2' className="flex flex-wrap pb-20">
                    <Testimonials/>
                </div>
            </div>
        </section>
    );
}
