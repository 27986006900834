import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';

import TestimonialSection from 'components/landing/TestimonialSection';

export default function Testimonials() {
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
                <TestimonialSection />
            </main>
            <DefaultFooter />
        </>
    );
}