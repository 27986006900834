import Title from 'components/landing/Title';
import TeamCard from 'components/landing/TeamCard';
import Eunice from '../../assets/img/EuniceSoloStraight.jpg'
import Johnny from '../../assets/img/JohnnySoloVertical.jpg'
import CardBody from '@material-tailwind/react/CardBody'


export default function TeamSection() {
    return (
        <section className="pt-20 pb-48 bg-gray-100">
            <div className="container max-w-7xl mx-auto px-4">
                <Title heading="Meet Our Team">
                    The Rise Up team consists of both Eunice and Johnny, active members of the team, founders and daily servants of the Lord.
                </Title>
                <div className="flex flex-wrap" style={{justifyContent : "space-around"}}>
                    <TeamCard
                        img={Eunice}
                        name="Eunice"
                        position="Founder & Servant of the Lord"
                        
                    />
                 
                    <TeamCard
                        img={Johnny}
                        name="Johnny"
                        position="Founder & Servant of the Lord"
                        
                    />
                </div>
                <CardBody>
              <div
                  id="test"
                  style={{ marginTop: "2%" }}
                  className="container max-w-7xl mx-auto px-4"
              >
                  <Title heading="Our Mission">
                      <p style={{ fontSize: 22 }} color="gray">
                          Our allegiance is first to Jesus and His gospel, and second to the
                          spiritual needs of the incarcerated. We are happy to partner with
                          any groups that share this same passion. It’s not surprising that
                          God is doing a great work behind the razor wire since Jesus
                          himself had such a heart for the lost, broken, hurting, and
                          forgotten. We consider ourselves blessed to have a front row seat
                          to God’s theater of grace. “Remember those who are in prison, as
                          though in prison with them…” Hebrews 13:3
                      </p>
                  </Title>
              </div>
          </CardBody>
            </div>
        </section>
    );
}
