import DefaultNavbar from "components/DefaultNavbar";
import DefaultFooter from "components/DefaultFooter";
import AboutSection from "components/about/AboutSection";
import { LogoDev } from "@mui/icons-material";
export default function AboutUs() {
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar 
                props={
                    <img src="../../assets/img/logo.png"/>
                }
                />
            </div>
            <main>
                <AboutSection/>
            </main>
            <DefaultFooter />
        </>
    );
}
