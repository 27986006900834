import Form from 'components/landing/Form';
import H3 from "@material-tailwind/react/Heading3"
import Paragraph from "@material-tailwind/react/Paragraph"
import Button from '@mui/material/Button';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

export default function ContactSection() {
    return (
        <section className="pb-20 relative block ">
            <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
                <Form />
                <div className="w-full text-center">
                            <H3 color="gray">Want to Donate?</H3>
                            <Paragraph color="blueGray">
                                Follow this link to donate to our cause!
                            </Paragraph>
                            <Button 
                                href="https://donorbox.org/rise-up-prison-ministry"
                                variant="contained" 
                                color='secondary' 
                                endIcon={<VolunteerActivismIcon />}>
                                Donate Now!
                            </Button>
                        </div>
            </div>
        </section>
    );
}
