export const EuniceBio = `
Eunice comes from a background of Catholicism, but found a true relationship with Jesus at age 32. 
She had a 13 year career in business with a multinational corporation, which she left to raise her two children and become involved in serving her local church.  
She homeschooled her children through the eighth grade, while serving in various ministries, including teaching youth and leading youth community groups, outreach, and missions.
Eunice loves the Lord and loves the Word.  She loves to teach topically from the bible and use her various life experiences to show that Jesus truly can meet your every need. 
She has a heart and a calling for the ministry of reconciliation and sharing what true saving faith in Jesus Christ looks like in the life of a believer. 
She feels she lived so much of her life practicing religion but almost missed eternity with her Lord and Savior by not understanding the truth about salvation.
She was introduced to prison ministry in 2018 and after one visit knew that the Lord was calling her to serve and love the incarcerated. 
Both of her children have joined her and Johnny at prison services and they fully support their mom in her ministry.
`;

export const JohnnyBio = `
Johnny is a living example of the redeeming power of Jesus to set the captive free.
Johnny spent 28 years living in the bondage of drug addiction which led him to serving 14 years in prisons over five different trips in the Missouri DOC, as well as over 35 stints in county jails, with the longest being seven months.
On February 24, 2007 Johnny had a life changing encounter with the love of Jesus at a chapel service in Jefferson City Correctional Center, a maximum security prison where he was serving a 35 year sentence. 
He was set free from addiction to drugs, pornography, and any desire to chase after the things of the world.
His case was eventually overturned and Johnny found himself walking out of prison March 21, 2011, a free man in every possible way.  
As a new believer, he entered in to a Christian Life Men’s program for 13 months to be discipled how to walk this newfound life.  Shortly after he began going back in to serve and share his testimony in prisons, and has been doing so ever since.  God has healed him in every way from his abusive past, but most treasured has been the restoration of his relationship with his three children and five grandchildren, who are all a beautiful part of his life and support him in the work he is doing for the incarcerated. 

`;
