import React from "react";
import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import Divider from "@mui/material/Divider";
import Title from "components/landing/Title";
import Image from "@material-tailwind/react/Image";
import Paragraph from '@material-tailwind/react/Paragraph';

import { Bio } from "./Carousel";
import Eunice from "../../assets/img/EuniceSoloStraight.jpg";
import Johnny from "../../assets/img/JohnnySoloVertical.jpg";
import { JohnnyBio } from "assets/bios/bios";
import { EuniceBio } from "assets/bios/bios";

export default function About() {
  return (
    <><Card>
          <CardBody>
              <div
                  id="test"
                  style={{ marginTop: "2%" }}
                  className="container max-w-7xl mx-auto px-4"
              >
                  <Title heading="Who Are We?">
                      <p style={{ fontSize: 22 }} color="gray">
                          We are non-denominational in our doctrine but adhere to the Holy
                          Bible as complete and inerrant Word of God, written by men
                          inspired through the Holy Spirit. We believe that God is the
                          eternal creator of all things and that He is Trinitarian in
                          existence as the Father, the Son, and the Holy Spirit. We believe
                          that salvation is by grace alone, through faith alone, in the
                          life, death, and resurrection of Jesus, the Messiah; the Son of
                          God who became flesh through a virgin birth. We believe that
                          salvation is the work of the Holy Spirit to convict, call and seal
                          us into that saving grace, and we also believe that the gifts of
                          the spirit are for all generations and meant to be used for the
                          edification of the Body of Christ. We believe that all have sinned
                          and fall short of the glory of God (Romans3:23); that the wages of
                          sin is death (Romans 6:23a); and that the gift of God is eternal
                          life in Christ Jesus our Lord (Romans 6:23b). We also believe that
                          salvation is active, not passive, and involves surrendering your
                          will to God’s will and making Jesus both Lord and Savior, and
                          following Him as a disciple.
                      </p>
                  </Title>
              </div>
          </CardBody>
          <Divider />
          <CardBody>
              <div
                  id="test"
                  style={{ marginTop: "2%" }}
                  className="container max-w-7xl mx-auto px-4"
              >
                  <Title heading="Our Service">
                      <p style={{ fontSize: 22 }} color="gray">
                          Our typical service consists of worship, teaching the Word of God,
                          exhortation and testimony, and prayer. We aim to bring truth that
                          will allow the Holy Spirit to convict, draw hearts towards God,
                          and grow in spiritual maturity. We also bring teaching that will
                          instruct a believer to understand their identity in Christ, and
                          how to utilize their faith to break strongholds in their lives,
                          since “It is for freedom that Christ has set us free.” Galatians
                          5:1
                      </p>
                  </Title>
              </div>
          </CardBody>
          <Divider />
          <CardBody>
              <div
                  id="test"
                  style={{ marginTop: "2%" }}
                  className="container max-w-7xl mx-auto px-4"
              >
                  <Title heading="What We Do">
                      <p style={{ fontSize: 22 }} color="gray">
                          We currently minister in 12 prisons across the state of Missouri.
                          We have weekly services at three institutions, and the remainders
                          are bi-monthly, monthly, or quarterly. We also work to coordinate
                          and assist in special events such as yard-jams, offender food
                          events, and concerts.
                      </p>
                  </Title>
              </div>
          </CardBody>          
      </Card>
      <section className="pt-20 pb-48">
              <div className="container max-w-7xl mx-auto px-4">
                  <Title heading="Eunice" />
                  <div
                          className="flex flex-wrap"
                          style={{ justifyContent: "center" }}
                      >
                          <Image
                              style={{ marginLeft: "0", marginBottom: '2%', width: "50%", height: "50%"}}
                              src={Eunice}
                              alt={"Eunice"}
                              raised
                              rounded={false} 

                              />
                              <Divider/>
                            <Card>
                                <CardBody>
                                    
                          <Paragraph style={{fontSize: 20 }} color="gray">
                              {EuniceBio}
                          </Paragraph>
                          </CardBody>
                          </Card>
                      </div>
              </div>
              <div className="container max-w-7xl mx-auto px-4">
                  <Title heading="Johnny" />
                  <div
                          className="flex flex-wrap"
                          style={{ justifyContent: "center" }}
                      >
                          <Image
                              style={{ marginLeft: "0", marginBottom: '2%', width: "50%", height: "50%"}}
                              src={Johnny}
                              alt={"Johnny"}
                              raised
                              rounded={false} 

                              />
                              <Divider/>
                            <Card>
                                <CardBody>
                                    
                          <Paragraph style={{fontSize: 20 }} color="gray">
                              {JohnnyBio}
                          </Paragraph>
                          </CardBody>
                          </Card>
                      </div>
              </div>
          </section></>
  );
}
